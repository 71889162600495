import React from "react"
import { Box } from "rebass"
import Layout from "../../components/_layout"
import SectionHeader from "../../components/_layout/section-header"
import Products from "../../components/products"
import Product1 from "../../assets/images/products/1.png"
import Product2 from "../../assets/images/products/2.png"
import Product3 from "../../assets/images/products/3.png"
import Product4 from "../../assets/images/products/4.png"
import Product5 from "../../assets/images/products/5.png"
import Divider from "../../components/divider"
import Container from "../../components/_layout/container"
import Dots from "../../components/dots"
import Pattern from "../../components/pattern"
import SEO from "../../components/seo"

const Assets = () => {
  return (
    <Layout>
      <SEO
        title="Our products"
        description="All the proprietary tech products born in the Spinup lab"
      />
      <SectionHeader title="Assets" />
      <Pattern opacity={0.2} />
      <Box bg="dark" color="white">
        <Divider
          theme="dark"
          firstLine="Thanks to our in-house team of full-stack developers, Spinup is able to offer a solution for almost any need that can be encountered in the world of performance marketing."
          secondLine="Get to know our proprietary products"
        />
      </Box>
      <Products
        backgroundColor="text"
        color="background"
        products={[
          { hidePattern: true },
          {
            image: Product1,
            hidePattern: true,
            patternColor: "primary",
            title: "Videoads",
            subtitle: "Face to face conversations at scale",
            description:
              "One video, one patented proprietary software, millions of interactions. Videoads allows the creation and the ideation of visual conversational flows that, thanks to the offered interactivity, generate an engagement rate of more than 27% on average. The result? Same traffic purchased, higher conversion rate.",
          },

          {
            hidePattern: true,
            image: Product2,
            patternColor: "watergreen",
            title: "Vinta",
            subtitle: "Tech for conversational marketing",
            description:
              "Vinta is the proprietary technology used by Spinup to deliver profiled leads to clients and partners. Vinta allows the creation of conversational chatbots that engage the user guiding it to a navigation experience in an intuitive, measurable and optimized manner. Spinup is leader in the field of conversational marketing.",
          },
          {
            hidePattern: true,
            image: Product3,
            patternColor: "standard",
            title: "Adverstory",
            subtitle: "Mobile-first navigation experiences",
            description:
              "Adverstory is the tool conceived by Spinup that allows the creation of lead generation landing pages in the form of stories, mobile-first medium par excellence. Thanks to Adverstory, Spinup is able to provide the final user a seamless experience compared to the social usually used, hence the advertising is perceived as less invasive. The conversion rate is thankful.",
          },

          {
            image: Product4,
            hidePattern: true,
            title: "Brain",
            subtitle: "The tech behind everything we do",
            description:
              "Brain is the centralized proprietary software upon which Spinup is built; with a distributed cloud architecture and no downtime ever registered, it allows to have the peace of mind that every click will be gathered and monetized. Born to smoothen the processes of a media tech company, Brain is the ally that allows Spinup to deliver massive results for itself and for its clients.",
          },
        ]}
      />
      <Container size="large" backgroundColor="text">
        <Dots
          pb={8}
          backgroundColor="text"
          dots={[
            {
              title: "Scalable",
              pointColor: "primary",
              borderColor: "background",
            },
            {
              title: "Proprietary",
              pointColor: "primary",
              borderColor: "background",
            },
            {
              title: "100% uptime",
              pointColor: "primary",
              borderColor: "background",
            },
          ]}
        />
      </Container>
      <Divider
        firstLine="When we are faced with a problem and we don't find any technology to solve it, we build it. If it doesn't solve it, we iterate the development until we reach the goal we've set"
        secondLine="We are Spinup, and we are a media tech company"
      />
    </Layout>
  )
}

export default Assets
